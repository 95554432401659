import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { IconDone } from 'icons/components/IconDone'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import replacePlaceholders from 'labels/lib/replacePlaceholders'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { Domains } from 'monitoring/constants/domains'
import { sendError } from 'monitoring/sendError/server/sendError'
import { useNotificationsContext } from 'notifications/hooks/useNotificationsContext'
import { useCallback, useState } from 'react'

import { GenericEvents } from '../../constants/analytics'
import { backInStockSubscriptionService } from '../../services/backInStockSubscriptionService'
import type { BackInStockSubscriptionServiceParams } from '../../types'
import { useBackInStockModalContext } from '../useBackInStockModalContext/useBackInStockModalContext'

export const useBackInStock = () => {
	const {
		country: { countryISO, locale },
	} = useMasterData()
	const { t } = useLabels()
	const { addNotification } = useNotificationsContext()
	const { backInStockModalProps, closeProductSubscription } =
		useBackInStockModalContext()

	const [subscribeError, setSubscribeError] = useState<boolean>(false)
	const [subscribeSuccess, setSubscribeSuccess] = useState<boolean>(false)

	const subscribeProduct = useCallback(async (inputEmail: string) => {
		if (!backInStockModalProps) {
			throw sendError({
				domain: Domains.Product,
				message: '[ProductInfo] Back in stock props for suscribe are undefined',
			})
		}

		const { productSizeId, productColorId, productId, feedbackDelay } =
			backInStockModalProps

		const params: BackInStockSubscriptionServiceParams = {
			productId,
			email: inputEmail,
			sizes: [productSizeId],
			colors: [productColorId],
			nativeApp: false,
			newsletter: false,
			countryIso: countryISO,
		}

		try {
			await backInStockSubscriptionService(params, locale)
			setSubscribeSuccess(true)
			sendGenericEvent(GenericEvents.NOTIFY_ME_SEND_SUCCEED, {
				sku: `${productId}-${productColorId}-${productSizeId}`,
			})

			setTimeout(() => {
				addNotification({
					id: 'backInStock.feedbackSuccess',
					title: replacePlaceholders(
						t('notifyMe.snackbar.feedback.noLogin.title'),
						{
							1: inputEmail,
						}
					),
					icon: <IconDone color='content-inverse' />,
					dataTestId: 'backInStock.feedback',
				})

				closeProductSubscription()
			}, feedbackDelay)

			backInStockModalProps.onSuccess?.()
		} catch {
			backInStockModalProps.onError?.()
			return setSubscribeError(true)
		}

		return undefined
	}, [])

	return {
		subscribeError,
		subscribeSuccess,
		subscribeProduct,
	}
}
