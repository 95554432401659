'use client'

import { DATA_TEST_ID } from 'back-in-stock/constants'
import { BackInStockForm } from 'back-in-stock/src/components/BackInStockModal/BackInStockForm/BackInStockForm'
import { useBackInStockModalContext } from 'back-in-stock/src/hooks/useBackInStockModalContext/useBackInStockModalContext'
import { ClientLabel } from 'labels/client'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'

import text from 'fukku/styles/texts.module.scss'
import styles from './BackInStockBody.module.scss'

export function BackInStockBody() {
	const { backInStockModalProps } = useBackInStockModalContext()

	const { pageType } = useMasterData()
	const { t } = useLabels()

	if (backInStockModalProps) {
		return (
			<div
				data-testid={`${pageType}.backInStock.dialog`}
				className={styles.body}
				aria-live='assertive'
				id='body-dialog-back-in-stock'
			>
				<h2
					data-testid={DATA_TEST_ID.productName}
					className={`${text.titleXL} ${styles.name}`}
				>
					{backInStockModalProps.productName}
				</h2>
				<div className={styles.subtitle}>
					<ClientLabel>{t('notifyMe.modal.subtitle')}</ClientLabel>
				</div>
				<div className={styles.formWrapper}>
					<BackInStockForm />
				</div>
			</div>
		)
	}

	return null
}
